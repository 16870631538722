/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    code: "code",
    pre: "pre",
    h3: "h3",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "CheatSheet"), "\n", React.createElement(_components.p, null, "Ignore the contents of dir ", React.createElement(_components.code, null, "foo/")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt:.gitignore"
  }, "foo/*\n")), "\n", React.createElement(_components.p, null, "Ignore dir ", React.createElement(_components.code, null, "foo/"), " anywhere in the project this is a bit overkill"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt:.gitignore"
  }, "**/foo/**\n")), "\n", React.createElement(_components.h3, null, "Example using .env"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt:.gitignore"
  }, "**.env**\n")), "\n", React.createElement(_components.p, null, "This ignores"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, ".env\n.env.foo\n.env.*\nfoo/.env\nfoo/.env.*\nfoo/bar/.env.*\n")), "\n", React.createElement(_components.h2, null, "Gotchas"), "\n", React.createElement(_components.h3, null, "Vscode"), "\n", React.createElement(_components.p, null, "Heavy handed handle, this ignores ", React.createElement(_components.code, null, ".vscode"), " anyware in a project and any sub configs"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, null, "**/.vscode/**\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-txt:.gitignore"
  }, "\n.vscode/*\n.vscode/settings.json\n.vscode/tasks.json\n.vscode/launch.json\n.vscode/extensions.json\n.vscode/*.code-snippets\n\n")), "\n", React.createElement(_components.h2, null, "References"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://www.atlassian.com/git/tutorials/saving-changes/gitignore"
  }, "gitignore reference - atlassian")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
